.base {
    min-height: 280px;
}

.content > * {
    margin-bottom: var(--offset-m);
}

.padded {
    padding: var(--offset-m) var(--offset-l);
}

.withTitle {
    padding-top: 0;
}

@media (min-width: 1440px) {
    .padded {
        padding: var(--offset-m) 50px;
    }
}
