.base {
    width: 360px;
}

.description {
    margin-bottom: var(--offset-xl);
    width: 100%;
    justify-content: center;
}

.title {
    text-align: center;
    font-size: 30px;
    color: var(--color-text-default);
}
