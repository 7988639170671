.popoverButton {
    margin-left: var(--offset-s);
    padding-right: 0;
}

.loginButton {
    color: #fff;
    margin-left: var(--offset-s);
}

.publishButton {
    margin-bottom: var(--offset-m);
}
