.header {
    background: var(--color-dark);
    display: flex;
    justify-content: space-between;
}

.logoLink {
    display: flex;
    color: #fff;
    align-items: center;
}

.logoLink span {
    display: inline-block;
    margin-left: 10px;
}

.help {
    color: #fff;
}

.help a span {
    margin-left: var(--offset-s);
}

.help a:not(:hover) {
    color: #fff;
}

.title {
    font-size: 16px;
    font-weight: 500;
    margin: 0 var(--offset-xl) 0 0;
    min-width: 195px;
}

.leftPanel {
    flex: 1;
}
