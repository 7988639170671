:root {
    --color-base: #F2F7F8;
    --color-dark: #0C7C85;
    --color-default: #04ACB9;
    --color-primary: #42E0D5;
    --color-secondary: #CBE3E8;
    --color-highlight: #71E8DF;
    --color-ultra-highlight: #e9fffd;
    --gradient: linear-gradient(90deg, #42E0D5 0%, #04ACB9 100%);
    --color-text-default: #091E42;
    --color-text-secondary: #97A0AF;
    --color-text-colored: #0C7C85;
    --color-text-disabled: #95C7D1;
    --color-warning: #FFB754;
    --color-scrollbar: #95C7D1;
    --color-scrollbar-track: #F2F7F8;
    --color-scrollbar-hover: #0C7C85;
}
