.base:global.ant-menu {
    background: transparent;
    flex: 1;
}

.base > :global.ant-menu-item-selected > a {
    color: #1890FF;
}

.base:global.ant-menu.ant-menu-dark > :global.ant-menu-item-selected {
    box-shadow: inset 0 -3px 0 0 var(--color-highlight);
}
