@import '~antd/dist/antd.css';
@import 'colors.config.css';
@import 'offsets.config.css';
@import 'custom.antd.css';

:root {
    --header-height: 76px;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--color-base);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

pre {
    font-family: monospace;
    font-size: 12px;
}

#root, .router {
    height: 100%;
}

.page-layout {
    min-height: 100%;
}

div::-webkit-scrollbar {
    width: 4px;
	height: 4px;
    border-radius: 2px;
}

div::-webkit-scrollbar-track {
    background: var(--color-scrollbar-track);
}

div::-webkit-scrollbar-thumb {
    background: var(--color-scrollbar);
    border-radius: 2px;
}

div::-webkit-scrollbar-thumb:hover {
    background: var(--color-scrollbar-hover);
}

.events {
    margin: 0;
    padding: 0;
    list-style: none;
}

.events .ant-tag {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
}
